:root {
    --pwaAvailableSpace: calc(100vh);
    --pwaAvailableProductListingsSpace: calc(100vh);
}

html,
body {
    overscroll-behavior: none;
    height: 100%;
}

html {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    background: #e5e5e5;
    display: flex;
    flex-direction: column;
    font-family: 'Material Symbols Outlined', 'Poppins', sans-serif;
}
#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.content {
    flex: 1 0 auto;
}

@media screen and (max-width: 900px) {
    html,
    body {
        overflow-x: hidden !important;
    }
    html {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    input {
        font-size: 16px !important;
    }
}
